<div class="banner-container">

  <!-- For carousel, add class="ratio" to the div below -->
  <div style="--bs-aspect-ratio: 50%; aspect-ratio: 2;">
    <!--ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="false">
      <ng-template ngbSlide>
        <!--img class="d-block img-fluid w-100" fetchpriority="high" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/banner-cwk-10-anniversary.gif" alt="Coding with Kids - 10th Anniversary" width="1300" height="650" /->
        <video #bannerVideo class="d-block img-fluid w-100" (canplay)="bannerVideo.play()" (loadedmetadata)="bannerVideo.muted = true" autoplay loop muted playsinline poster="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/banner-cwk-10-anniversary-poster.jpg" width="1300" height="650">
          <source src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/banner-cwk-10-anniversary.webm" type="video/webm" />
          <source src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/banner-cwk-10-anniversary.mp4" type="video/mp4" />
        </video>

        <!-- BOTTOM SECTION ->
        <div class="absolute-element bottom-section">
          <div class="d-none d-md-block w-100">
            <ng-container *ngTemplateOutlet="programButtonsAnniversary"></ng-container>
          </div>
        </div>
      </ng-template>
      <ng-template ngbSlide-->

        <!-- DEFAULT BANNER -->
        <video #bannerVideo class="d-block img-fluid w-100" (canplay)="bannerVideo.play()" (loadedmetadata)="bannerVideo.muted = true" autoplay loop muted playsinline poster="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/banner-poster.jpg" width="1300" height="650">
          <source src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/banner.webm" type="video/webm" />
          <source src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/banner.mp4" type="video/mp4" />
        </video>

        <!--img class="d-block img-fluid w-100" fetchpriority="high" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/top-banner-plain.jpg" alt="Coding with Kids - #1 in CS Curriculum" width="1300" height="650" /-->

        <!-- TOP SECTION -->
        <!--div class="absolute-element top-section">
          <div class="seal-img">
            <img class="img-fluid" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/anniversary-seal.png" alt="Coding with Kids 10th anniversary seal" loading="lazy" width="223" height="226" />
            <!-img class="img-fluid cwk-pointer" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/seal-2.png" alt="Coding with Kids quality guaranteed" (click)="scrollToAnchor('commitment-to-quality')" loading="lazy" width="300" height="240" /->
          </div>
        </div-->

        <!-- BOTTOM SECTION -->
        <div class="absolute-element bottom-section">
          <div class="global-academy-text">
            <div>#1 Coding Academy for&nbsp;Kids&nbsp;&amp;&nbsp;Teens</div>
            <div class="d-none d-sm-block sub-title">Online & In-person</div>
          </div>
          <div class="d-none d-md-block w-100">
            <ng-container *ngTemplateOutlet="programButtons"></ng-container>
          </div>
        </div>
      <!--/ng-template>
    </ngb-carousel-->
  </div>
</div>

<div class="orange-bar d-flex justify-content-between align-items-center">
  <home-customer-feedback-rating mode="banner" (click)="scrollToAnchor('what-parents-say-about-us')"></home-customer-feedback-rating>
  <div>
    <a href="http://www.facebook.com/CodingWithKids" target="_blank">
      <img class="img-fluid social-icon me-3" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/facebook.png" alt="Facebook logo" loading="lazy"/>
    </a>
    <!--a href="https://www.twitter.com/CodingWithKids" target="_blank">
      <img class="img-fluid social-icon" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/twitter.png" alt="Twitter logo" loading="lazy"/>
    </a-->
  </div>
</div>

<!-- POPULAR PROGRAMS (MOBILE ONLY) -->
<div id="popular-programs" class="d-block d-md-none">
  <h3 class="cwk-orange text-center mt-3 mb-3">POPULAR PROGRAMS</h3>
  <ng-container *ngTemplateOutlet="programButtons"></ng-container>
  <hr class="mt-4 mb-0"/>
</div>

<!-- ANNIVERSARY OFFER -->
<!--div class="page-spacer">
  <home-anniversary-offer></home-anniversary-offer>
</div>
<hr class="my-5"/-->

<!-- INFO GRAPHIC TILES -->
<div class="page-spacer">
  <home-info-graphics></home-info-graphics>
</div>
<hr class="my-5"/>

<!-- COMPUTER SCIENCE PROGRAMS -->
<div id="premier-computer-science-program" class="live-online-programs page-spacer d-flex flex-column mb-3">
  <h1 class="cwk-orange text-center">COMPUTER SCIENCE PROGRAMS</h1>
  <h3 class="text-center mb-0">Comprehensive coding curriculum for ages 5-18</h3>
  <div class="mx-auto mt-4 mini-orange-bar"></div>
</div>

<!-- PROGRAM LINKS -->
<div class="page-spacer">
  <div *ngIf="isReading" class="cwk-loading cwk-orange" [@slideInOut]>
    <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>
  </div>
  <home-program-tiles *ngIf="!isReading" [configuration]="configuration"></home-program-tiles>
</div>
<div class="my-5"></div>
<hr class="my-5"/>

<!-- CODER'S LADDER AND CODER'S PATHWAYS -->
<div id="programs-that-grow-with-your-coder" class="page-spacer">
  <h1 class="cwk-orange text-center">CHOOSE YOUR CODER'S PATH</h1>
  <!--h3 class="cwk-darkyellow text-center mb-0">Choose your own path</h3-->
  <!--div class="mx-auto my-4 mini-orange-bar"></div-->
  <home-coders-ladder-pathways></home-coders-ladder-pathways>
</div>
<hr class="my-5"/>

<!-- Mailing list -->
<cwk-join-mailing-list *ngIf="geolocation" [country]="geolocation?.country_code" source="Homepage" title="Stay in the know on all of our programs and announcements:"></cwk-join-mailing-list>
<hr class="my-5"/>

<!-- Teaching Philosophy -->
<div id="teaching-philosophy" class="page-spacer">
  <h1 class="cwk-orange text-center mb-4">TEACHING PHILOSOPHY</h1>
  <home-teaching-philosophy></home-teaching-philosophy>
</div>
<hr class="my-5"/>

<!-- Quality commitment -->
<div id="commitment-to-quality" class="page-spacer">
  <h1 class="cwk-orange text-center">COMMITMENT TO QUALITY</h1>
  <home-quality-commitment></home-quality-commitment>
</div>

<!-- Customer Testimonials -->
<div id="what-parents-say-about-us" class="grey-section page-spacer">
  <h1 class="cwk-orange text-center mb-3">WHAT PARENTS AND STUDENTS SAY ABOUT US</h1>
  <div class="d-flex justify-content-center mb-3">
    <home-customer-feedback-rating mode="testimonial"></home-customer-feedback-rating>
  </div>
  <home-customer-testimonials></home-customer-testimonials>
</div>

<!-- Blog posts -->
<div id="blog-posts" class="page-spacer">
  <home-blog-posts-carousel mode="BLOG"></home-blog-posts-carousel>
</div>
<hr class="my-5"/>

<!-- Seasonal update -->
<!--div class="page-spacer">
  <h1 class="cwk-orange text-center mb-3">FALL UPDATES</h1>

  <div id="seasonal-update">
    <div class="mb-4">
      <div class="fw-bold">Spring Break Camps: <a id="seasonal-update-camps" tm-destination="/online-camps" class="cwk-blue-link" [routerLink]="['/online-camps']" [queryParams]="{ season: '2021_SPRING_BREAK' }" queryParamsHandling="merge">REGISTER HERE</a></div>
      <div>Camps are back! These fun-filled week long programs will meet for 1-3 hours each day, depending on the age and topic. It’s extra fun to code together with friends or cousins, so don’t forget to invite them, too!</div>
    </div>
    <div class="fw-bold"><span class="cwk-orange">*NEW*</span> Camps!</div>
    <div class="fw-bold">Enrichment Classes</div>
    <div class="mb-4">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae volutpat est, ullamcorper rutrum velit. Proin neque nibh, condimentum sed lacus vel, lacinia blandit nibh. Duis arcu est, laoreet tempus ex pulvinar, viverra ultricies metus. Sed maximus, sapien sed auctor vestibulum, risus libero vestibulum dolor, non commodo nisi sem a justo. In nec risus quis diam volutpat placerat.
    </div>
    <div class="mb-4">
      <div class="fw-bold">Online Enrichment Classes: <a id="seasonal-update-online-enrichment" tm-destination="/online-enrichment-classes" class="cwk-blue-link" [routerLink]="['/online-enrichment-classes']" queryParamsHandling="merge">REGISTER HERE</a></div>
      <ul>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae volutpat est, ullamcorper rutrum velit. Proin neque nibh, condimentum sed lacus vel, lacinia blandit nibh.</li>
        <li>Duis arcu est, laoreet tempus ex pulvinar, viverra ultricies metus. Sed maximus, sapien sed auctor vestibulum, risus libero vestibulum dolor, non commodo nisi sem a justo. In nec risus quis diam volutpat placerat.</li>
      </ul>
    </div>
    <div clas="mb-4">
      <div class="fw-bold">In-Person Enrichment Classes: <a id="seasonal-update-online-enrichment" tm-destination="/in-person-enrichment-classes" class="cwk-blue-link" [routerLink]="['/in-person-enrichment-classes']" queryParamsHandling="merge">REGISTER HERE</a></div>
      <ul>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae volutpat est, ullamcorper rutrum velit. Proin neque nibh, condimentum sed lacus vel, lacinia blandit nibh.</li>
        <li>Duis arcu est, laoreet tempus ex pulvinar, viverra ultricies metus. Sed maximus, sapien sed auctor vestibulum, risus libero vestibulum dolor, non commodo nisi sem a justo. In nec risus quis diam volutpat placerat.</li>
      </ul>
    </div>

    Would you like us to bring our afterschool enrichment programs to your school? We offer both <b>In-person and virtual</b> options for grades K-12! To learn more or contact us, visit our <a [routerLink]="['/after-school-enrichment']" queryParamsHandling="preserve" class="cwk-blue-link cwk-link"><b>Enrichment Page for Schools &amp; PTAs</b>.</a>

    <div class="text-center cwk-link cwk-blue-link mt-4">
      <span (click)="switchIsSeasonalUpdateOpen()">Show {{ isSeasonalUpdateOpen ? 'Less' : 'More' }}...</span>
    </div>

    <div *ngIf="isSeasonalUpdateOpen" [@slideInOut]>
      <div class="my-4 text-center">
        <em>Beyond our fall enrichment classes, we will continue to offer all our online programs:</em>
      </div>
      <div class="mb-4">
        <div class="fw-bold">Coder's Ladder<span class="cwk-registration-icon">&reg;</span> Classes: <a id="seasonal-update-cl-classes" tm-destination="/coders-ladder-group-classes" class="cwk-blue-link" [routerLink]="['/coders-ladder-group-classes']" queryParamsHandling="preserve">REGISTER HERE</a></div>
        <div>Coding with Kids' flagship program offers once-a-week and twice-a-week classes that meet year-round. Join any time!</div>
      </div>
      <div class="mb-4">
        <div class="fw-bold">Enrichment Classes (a.k.a. Coder's Pathways<span class="cwk-registration-icon">&reg;</span> Classes): <a id="seasonal-update-enrichment-classes" tm-destination="/online-enrichment-classes" class="cwk-blue-link" [routerLink]="['/online-enrichment-classes']" queryParamsHandling="preserve">REGISTER HERE</a></div>
        <div>If you are looking for a short 6 or 10 week session of once-a-week classes or would like to try enrichment topics above and beyond core coding - such as Minecraft Modding or Roblox or Micro:bit robotics - the enrichment classes are the way to go!</div>
      </div>
      <div class="mb-4">
        <div class="fw-bold">Private Classes: <a id="seasonal-update-private-classes" tm-destination="/private-classes" class="cwk-blue-link" [routerLink]="['/private-classes']" queryParamsHandling="preserve">REGISTER HERE</a></div>
        <div>Sign up for one-on-one personalized instruction by your superstar tech instructor! Available for both Coder’s Ladder<span class="cwk-registration-icon">&reg;</span> and Coder's Pathways<span class="cwk-registration-icon">&reg;</span> classes. Give your avid young coder an extra boost!</div>
      </div>
      <div class="mb-4">
        <div class="fw-bold">Classes for Adults: <a id="seasonal-update-parent-classes" tm-destination="/classes-for-adults" class="cwk-blue-link" [routerLink]="['/classes-for-adults']" queryParamsHandling="preserve">REGISTER HERE</a></div>
        <div>Interested in building your own website, designing a greeting card, or keeping up with your star coder? Jumpstart your coding education with our classes for adults!</div>
      </div>
    </div>
  </div>
</div>
<hr class="my-5"/-->

<!-- Superstar spotlight -->
<div id="our-students" class="page-spacer">
  <home-blog-posts-carousel mode="SUPERSTAR"></home-blog-posts-carousel>
</div>
<hr class="my-5"/>

<!-- Awards and recognition -->
<div id="awards-and-recognition" class="page-spacer">
  <h1 class="cwk-orange text-center">AWARDS AND RECOGNITION</h1>
  <home-awards-and-recognition></home-awards-and-recognition>
</div>

<!-- School Partnerships -->
<div id="school-partnerships" class="grey-section page-spacer-double">
  <h1 class="cwk-orange text-center mb-5">SCHOOL PARTNERSHIPS</h1>
  <div class="row">
    <div class="col-md-6 mb-5 mb-md-0">
      <h4 class="text-center fw-bold">CS Electives</h4>
      <div class="text-center mb-3">
        Coding with Kids has been teaching in-school Computer Science classes at numerous US schools. Our most recent partner is Seattle Country Day School, where we teach coding classes to students in all middle school grades. Seattle Country Day School is a well renowned private school with focus on education for Gifted Children. <a id="read-more-for-schools" tm-destination="/coding-programs-for-schools" class="cwk-link cwk-blue-link" [routerLink]="['/coding-programs-for-schools']" queryParamsHandling="preserve">Read more...</a>
      </div>
      <img class="mx-auto d-block" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/partner-logos/SCDS.jpg" loading="lazy" alt="Seattle Country Day School"/>
    </div>
    <div class="col-md-6">
      <h4 class="text-center fw-bold">AP-CS Courses</h4>
      <div class="text-center mb-3">
        Coding with Kids has been teaching the AP Computer Science courses at the Bear Creek School in Redmond, WA since 2015.  We are proud to be partnering with the #1 Christian High School in the state of Washington and proud to be providing highly qualified teachers with academic and industry experience. <a id="read-more-for-schools" tm-destination="/coding-programs-for-schools" class="cwk-link cwk-blue-link" [routerLink]="['/coding-programs-for-schools']" queryParamsHandling="preserve">Read more...</a>
      </div>
      <img class="mx-auto d-block" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/partner-logos/bear-creek.png" loading="lazy" alt="The Bear Creek School"/>
    </div>
  </div>
  <h4 class="text-center mt-5 mb-2 fw-bold">Afterschool Enrichment</h4>
  <div class="text-center mb-4">Coding with Kids has been providing afterschool enrichment coding classes at over 600 partner schools since 2014. <a id="read-more-as-enrichment" tm-destination="/coding-programs-for-schools" class="cwk-link cwk-blue-link" [routerLink]="['/coding-programs-for-schools']" queryParamsHandling="preserve">Read more...</a></div>
  <div class="row">
    <div class="col-lg-4 mb-4 mb-lg-0">
      <img class="mx-auto d-block" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/partner-logos/LWSDL.png" loading="lazy" alt="Lake Washington School District"/>
    </div>
    <div class="col-lg-4 mb-4 mb-lg-0">
      <img class="mx-auto d-block" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/partner-logos/DPS.png" loading="lazy" alt="Denver Public Schools"/>
    </div>
    <div class="col-lg-4">
      <img class="mx-auto d-block" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/partner-logos/AISD.png" loading="lazy" alt="Austin Independent School District"/>
    </div>
  </div>
</div>
<div class="page-spacer"></div>

<!-- PROGRAM BUTTON TEMPLATE -->
<ng-template #programButtons>
  <div class="d-flex flex-column flex-md-row align-items-center justify-content-center w-100">
    <div *ngTemplateOutlet="bannerBtn; context: { data: { title: configuration.bannerLeftBtnTitle, type: configuration.bannerLeftBtn }}"></div>
    <div class="program-buttons-spacer">&nbsp;</div>
    <div *ngTemplateOutlet="bannerBtn; context: { data: { title: configuration.bannerRightBtnTitle, type: configuration.bannerRightBtn }}"></div>
  </div>
</ng-template>

<!-- PROGRAM BUTTON TEMPLATE FOR ANNIVERSARY OFFER -->
<ng-template #programButtonsAnniversary>
  <div class="d-flex flex-column flex-md-row align-items-center justify-content-start px-3 px-lg-5 w-100">
    <div *ngTemplateOutlet="bannerBtn; context: { data: { title: configuration.bannerLeftBtnTitle, type: configuration.bannerLeftBtn }}"></div>
    <div class="program-buttons-spacer">&nbsp;</div>
    <div *ngTemplateOutlet="bannerBtn; context: { data: { title: configuration.bannerRightBtnTitle, type: configuration.bannerRightBtn }}"></div>
  </div>
</ng-template>

<ng-template #bannerBtn let-data="data">
  <ng-container *ngIf="data.type === 'CAMP'">
    <ng-container *ngTemplateOutlet="campBtn; context: { title: data.title }"></ng-container>
  </ng-container>
  <ng-container *ngIf="data.type === 'CODERS-LADDER'">
    <ng-container *ngTemplateOutlet="codersLadderBtn; context: { title: data.title }"></ng-container>
  </ng-container>
  <ng-container *ngIf="data.type === 'ENRICHMENT'">
    <ng-container *ngTemplateOutlet="enrichmentBtn; context: { title: data.title }"></ng-container>
  </ng-container>
  <ng-container *ngIf="data.type === 'PRIVATE'">
    <ng-container *ngTemplateOutlet="privateBtn; context: { title: data.title }"></ng-container>
  </ng-container>
  <ng-container *ngIf="!data.type">
    <ng-container *ngTemplateOutlet="loadingBtn"></ng-container>
  </ng-container>
</ng-template>

<ng-template #campBtn let-title="title">
  <a id="program-banner-camp" tm-destination="/online-camps" class="program-button cwk-link" [routerLink]="['/online-camps']" queryParamsHandling="preserve">
    <!--div class="no-bold fs-small mt-3">Live Online</div-->
    <div class="position-relative my-3">
      {{ title }}
      <!--img class="img-fluid new-camps-img" src="https://d3t4xfu733v2tb.cloudfront.net/logo/new-camps-icon.png" alt="New camps image" loading="lazy"/-->
      <img *ngIf="showEarlyBird" class="img-fluid early-bird-img" src="https://d3t4xfu733v2tb.cloudfront.net/logo/{{ fileEarlyBird }}" alt="Early bird image" loading="lazy" width="500" height="315" />
    </div>
    <!--div class="d-flex justify-content-around no-bold">
      <span class="inner-btn" (click)="$event.preventDefault()" [routerLink]="['/in-person-camps']" queryParamsHandling="preserve">In-person</span>
      <span class="inner-btn">Live Online</span>
    </div-->
  </a>
</ng-template>

<ng-template #codersLadderBtn let-title="title">
  <a id="program-banner-coders-ladder" tm-destination="/coders-ladder-group-classes" class="program-button cwk-link" [routerLink]="['/coders-ladder-group-classes']" queryParamsHandling="preserve">
    <!--div class="no-bold fs-small mt-3">Live Online</div-->
    <div class="position-relative my-3">
      {{ title }}
    </div>
    <!--div class="d-flex justify-content-around no-bold">
      <span class="inner-btn">Small Group</span>
      <span class="inner-btn" (click)="$event.preventDefault()" [routerLink]="['/private-classes']" queryParamsHandling="preserve">Private Classes</span>
    </div-->
  </a>
</ng-template>

<ng-template #enrichmentBtn let-title="title">
  <a id="program-banner-enrichment" tm-destination="/online-enrichment-classes" class="program-button cwk-link" [routerLink]="['/online-enrichment-classes']" queryParamsHandling="preserve">
    <!--div class="no-bold fs-small mt-3">Small Group</div-->
    <div class="position-relative my-3">
      {{ title }}
    </div>
    <!--div class="d-flex justify-content-around no-bold">
      <span class="inner-btn">Live Online</span>
      <span class="inner-btn" (click)="$event.preventDefault()" [routerLink]="['/in-person-enrichment-classes']" queryParamsHandling="preserve">In-person</span>
    </div-->
  </a>
</ng-template>

<ng-template #loadingBtn>
  <a class="program-button cwk-link">
    <!--div class="no-bold">&nbsp;</div-->
    <div class="position-relative my-3">
      <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>
    </div>
    <!--div class="d-flex justify-content-around no-bold">
      <span class="inner-btn">&nbsp;</span>
    </div-->
  </a>
</ng-template>

<ng-template #privateBtn let-title="title">
  <a id="program-banner-coders-ladder" tm-destination="/private-classes" class="program-button cwk-link" [routerLink]="['/private-classes']" queryParamsHandling="preserve">
    <!--div class="no-bold fs-small mt-4">Live Online</div-->
    <div class="position-relative my-3">
      {{ title }}
    </div>
    <!--div class="d-flex justify-content-around no-bold">
      <span class="inner-btn">Small Group</span>
      <span class="inner-btn" (click)="$event.preventDefault()" [routerLink]="['/private-classes']" queryParamsHandling="preserve">Private Classes</span>
    </div-->
  </a>
</ng-template>
